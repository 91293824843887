$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.checkboxContainer {
    label {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 21px;
        cursor: pointer;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            left: 0;
            width: 13px;
            height: 13px;
            outline: 2px solid #acacac;
            border-radius: 1px;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        input:checked ~ .checkmark {
            background-color: $primary-color;
            outline: 2px solid $primary-color;
        }
        input:checked ~ .checkmark:after {
            display: flex;
        }
    }
    label:hover input ~ .checkmark {
        opacity: 0.8;
    }
    label .checkmark:after {
        left: 4px;
        top: 2px;
        width: 2px;
        height: 7px;
        border: solid white;
        border-width: 0px 2px 2px 0;
        transform: rotate(45deg);
    }
}
