$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.inputContainer {
    width: 100%;
    label {
        display: flex;
        flex-flow: column;
        width: 100%;
        position: relative;
        align-items: flex-start;
        
        input {
            margin-top: 4px;
            padding: 12px 16px;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-bottom: 10px;
            font-size: 0.9rem;
            width: 100%;
        }
        input::placeholder{
            color: #999999  ;
        }
        input:focus {
            outline: 2px solid $primary-color;
        }
        input:disabled {
            opacity: 0.7;
            pointer-events: none;
            //font-size: 1rem;
        }
        //autofill
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        }
        input:-webkit-autofill:disabled {
            -webkit-box-shadow: 0 0 0 30px #e0e0e0 inset !important;
        }
        p {
            position: absolute;
            bottom: -6px;
            left: 2px;
            font-weight: 500;
            color: $error-color;
            font-size: 0.75rem;
        }
    }
}
