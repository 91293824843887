$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;
.container {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-flow: column;

    .content {
        max-width: 85%;
        height: fit-content;
        width: fit-content;
        min-width: 230px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 2px 3px #cecece;
        position: relative;
        padding: 35px;
        h2 {
            color: #ffffff;
            top: -45px;
            position: absolute;
            text-align: center;
            text-transform: capitalize;
            width: 100%;
            left: 0;
        }
        .logout {
            position: absolute;
            color: #002c3f;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            gap: 3px;
            cursor: pointer;
            justify-content: center;
            width: 100%;
            left: 0;
            text-align: center;
            bottom: -30px;
            font-weight: 500;
            i {
                background-image: url("../../assets/icons/logout.svg");
                background-size: cover;
                width: 15px;
                height: 17px;
                position: relative;
                top: 2px;
            }
        }
        .logout:hover {
            opacity: 0.8;
        }
    }
    .errorMessage {
        width: 100%;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            position: absolute;
            background: rgba($color: $primary-color, $alpha: 0.2);
            color: #ffffff;
            padding: 15px 20px;
            width: 310px;
            border-radius: 5px;
            transition: all 0.2s ease-out;
        }
        p {
            font-weight: 500;
        }
        .showErrorMessage {
            bottom: 20px;
        }
        .hideErrorMessage {
            bottom: -50px;
        }
    }
}
.container::before,
.container::after {
    content: "";
    margin: 65px auto auto;
}
