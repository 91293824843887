.contentWrapper {
    width: 100%;
    // min-height: 70%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.formSubmit {
    margin-top: 24px;
}

.inputWrapper {
    margin-bottom: 12px;
    width: 100%
}

.row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    direction: rtl;
}

.editEmployeeButtton {
    width: 10%;
    display: flex;
    justify-content: center;
    color: royalblue;
    text-decoration: underline;
    cursor: pointer;
}

.deleteEmployeeButtton {
    width: 10%;
    display: flex;
    justify-content: center;
    color: crimson;
    text-decoration: underline;
    cursor: pointer;
}

.card {
    width: 800px;
    height: 48px;
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: rgba(79, 79, 79, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 12px;
    margin-right: 8px;
}

.titleCard {
    background: none;
    border: 1px solid #222;
    font-weight: bold;
    width: 800px;
    height: 48px;
    margin-bottom: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 12px;
}

.addButtonwrapper {
    width: 150px;
    margin-bottom: 12px;
    margin-left: 8px;

}

.cardInfo {
    width: 26%;
    display: flex;
    justify-content: center;
}

.deleteText {
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
}

.smallSubmit {
    width: 49%;
    margin-right: 8px;
}

.smallSubmit:first-child {
    margin-right: 0px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 50%;
    padding-left: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    // border-radius: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
    width: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}