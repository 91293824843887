$primary-color:#002c3f;
$secondary-color:#1e5971;
$error-color:#ff1b1b;

.buttonContainer {
    width: 100%;
    button {
        padding: 10px 20px;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        font-size: 0.9rem;
        margin-top: 5px;
    }
    button:disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
    .light {
        background-color: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
    }
    .light:hover{
        background-color: #f3f3f3;
    }
    .white {
        background-color: #e0dfdf;
        border: 1px solid #999;
        color: #222;
    }
    .white:hover{
        background-color: #c1c1c1;
    }
    .dark{
        background-color: $primary-color;
        border: $primary-color;
        color: #ffffff;
    }
}
