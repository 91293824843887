.dropdownWrapper {
    direction: ltr;
    font-family: IRANSans !important;
    width: inherit;
  
    label {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #222222;
    }
  
    .dropdownButton {
      min-width: 72px;
      // max-width: 130px;
      height: 44px;
      width: fit-content;
      margin: 0 0 4px 0px;
      padding: 8px 24px 7px;
      border-radius: 8px;
      border: solid 1px #192a56;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #192a56;
    }
  
    .dropdownHover {
      @extend .dropdownButton;
      background-color: #e4e6eb;
      cursor: pointer;
    }
  
    .dropdownButton:hover {
      cursor: pointer;
      background-color: #e4e6eb;
    }
  
    button {
      font: inherit !important;
    }
  
    button:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  
    .dropdownButtonSelected {
      @extend .dropdownButton;
      background-color: #192a56;
      color: white;
    }
  
    .dropdownButtonSelected:hover {
      background-color: #344980;
      cursor: pointer;
    }
  
    .dropdownContent {
      display: block;
      position: absolute;
      min-width: 212px;
      width: inherit;
      max-height: 307px;
      opacity: 1;
      border-radius: 8px;
      border: solid 1px #8b93a9;
      background-color: #ffffff;
      margin-top: 4px;
      transition: max-height 0.5s ease;
      z-index: 2;
    }
  
    .dropdownContentHide {
      max-height: 0px;
      display: block;
      position: absolute;
      min-width: 212px;
      width: 100%;
      width: inherit;
      opacity: 1;
      border-radius: 8px;
      border: hidden 1px transparent;
      background-color: #ffffff;
      margin-top: 4px;
      transition: max-height 0.5s ease;
      z-index: 2;
    }
  
    .contentDiv {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      max-height: 186px;
      min-width: 212px;
      height: auto;
      transition: max-height 0.5s ease;
    }
  
    .contentHideDiv {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 0px;
      min-width: 212px;
      height: auto;
      transition: max-height 0.5s ease;
    }
  
    .contentHideDiv .blueCheckMark {
      display: none;
      position: none;
    }
  
    /*-----------------------scroll bar----------------
  --------------------------------------------------*/
  
    /* width */
    .contentDiv::-webkit-scrollbar,
    .contentHideDiv::-webkit-scrollbar {
      width: 13px;
      padding: 0px;
    }
  
    /* Track */
    .contentDiv::-webkit-scrollbar-track,
    .contentHideDiv::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 50px;
    }
  
    /* Handle */
    .contentDiv::-webkit-scrollbar-thumb,
    .contentHideDiv::-webkit-scrollbar-thumb {
      background-color: #192a56;
      border: 5px solid transparent;
      border-radius: 50px;
      background-clip: content-box;
    }
  
    /* Handle on hover */
    .contentDiv::-webkit-scrollbar-thumb:hover,
    .contentHideDiv::-webkit-scrollbar-thumb:hover {
      background: #192a56;
      cursor: pointer;
      border: 5px solid transparent;
      border-radius: 50px;
      background-clip: content-box;
    }
  
    /*-----------------------scroll bar----------------
  --------------------------------------------------*/
    ul {
      margin-right: 0px;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-right: 0px;
      list-style: none;
      padding: 0px 0px 4px 0px;
      min-width: 212px;
    }
  
    li {
      width: 100%;
      min-width: 190px;
      height: 40px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: right;
      color: #222222;
      background-color: transparent;
      padding: 4px 10px 4px 12px;
      transition: background-color 0.3s;
    }
  
    li:hover {
      background-color: #f1f2f6;
      cursor: pointer;
    }
  
    .dropdownArrow {
      width: 16px;
      height: 16px;
      margin-left: 6px;
      margin-top: 4px;
      transition: transform 0.3s;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      float: left;
    }
  
    .dropdownArrowClicked {
      width: 16px;
      height: 16px;
      margin-left: 6px;
      margin-top: 4px;
      transition: transform 0.3s;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      float: left;
    }
  
    .toggleWrapper {
      direction: ltr;
      display: flex;
      align-items: center;
      height: 100%;
  
      p {
        margin-right: 12px;
        margin-left: 34px;
        font-size: 14px;
        width: calc(100% - 64px);
        box-sizing: border-box;
      }
  
      .switch {
        width: 40px;
        height: 20px;
        margin-left: 12px;
      }
    }
  
    .customCheckboxWrapper {
      direction: ltr;
      display: flex;
      align-items: center;
      height: 100%;
  
      p {
        margin: 0 12px;
        height: 21px;
      }
  
      .inputCheckboxClass {
        float: right;
        padding: 0px;
        margin: 0px;
        appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: solid 1px #192a56 !important;
        display: inline-block;
        position: relative;
      }
  
      .inputCheckboxClass:hover {
        opacity: 0.7;
        cursor: pointer;
      }
  
      .inputCheckboxClass:active {
        // box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        border: none !important;
      }
  
      .inputCheckboxClass:focus {
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        border: none !important;
      }
  
      .inputCheckboxClass:visited {
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        // border: solid 1px #192a56 !important;
      }
  
      .inputChecked:active {
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        border: none !important;
      }
  
      .inputChecked:visited {
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        // border: solid 1px #192a56 !important;
      }
  
      .inputChecked:focus {
        box-shadow: 0px 0px 0px 2px rgba(52, 73, 128, 0.33) !important;
        outline: none !important;
        border: none !important;
      }
  
      .inputChecked:focus.inputChecked:after {
        top: 2px;
        right: 2px;
      }
  
      .inputChecked:hover {
        opacity: 0.7;
        cursor: pointer;
      }
  
      .inputChecked {
        background-color: #192a56 !important;
        border: 1px solid #adb8c0;
        color: #ffffff;
        float: right;
        padding: 0px;
        margin: 0px;
        appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        display: inline-block;
        position: relative;
      }
  
      .inputChecked:after {
        // content: '✓';
        content: url("../../../assets/icons/tick.svg");
        font-size: 13px;
        position: absolute;
        top: 1px;
        right: 1px;
        width: 12px;
        height: 12px;
        color: #ffffff;
      }
  
      p {
        // float: right;
        margin-top: -2px;
        // margin-right: 8px;
      }
    }
  
    .itemSelectedWrapper {
      background-color: #e4ebff;
    }
  
    .contentHideDiv .itemSelectedWrapper {
      background-color: transparent;
    }
  
    .itemNotSelectedWrapper {
      background-color: transparent;
    }
  
    .blueCheckMark {
      position: absolute;
      right: 4px;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      float: right;
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;
    }
  
    .notcheckBoxText {
      direction: ltr;
    }
  
    .searchWrapper {
      width: inherit;
      min-width: 212px;
      padding: 0px !important;
      background-color: white;
      margin-bottom: 50px;
      position: relative;
      transition: display 0.3s;
  
      input {
        padding-right: 12px !important;
        // width: 98%;
      }
    }
  
    .searchHideWrapper {
      display: none;
      width: inherit;
      padding: 0px !important;
      margin-bottom: 50px;
      background-color: white;
      position: relative;
      transition: display 0.3s;
    }
  
    .dropdownSearch {
      width: 100%;
      height: 40px;
      position: absolute;
      padding: 0px !important;
      background-color: #f0f0f0;
      border: none;
      margin-top: 7px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      font: inherit !important;
      overflow-x: hidden;
      z-index: 1;
      // padding-right: 12px;
      box-sizing: border-box;
    }
  
    .dropdownSearch::placeholder {
      color: #999999;
      margin-right: 20px;
      text-align: right;
      direction: ltr;
      // padding-right: 12px;
    }
  
    .dropdownSearch:focus,
    .dropdownSearch:active {
      outline: none;
      border: none;
      box-shadow: none;
    }
  
    .searchMagnifier {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 17px;
      left: 10px;
      float: left;
      z-index: 2;
    }
  
    .dropdownActionsWrapper {
      width: inherit;
      min-width: 240px;
      height: 48px;
      background-color: #ffffff;
      transition: display 0.3s;
      border-radius: 0px 0px 8px 8px;
      border-top: 1px solid #e7e9ee;
      transition: height 0.3s;
    }
  
    .dropdownActionsWrapperHide {
      display: none;
      width: inherit;
      min-width: 212px;
      height: 0px;
      background-color: #ffffff;
      transition: display 0.3s;
      border-radius: 0px 0px 8px 8px;
      border-top: none;
      transition: height 0.3s;
    }
  
    .addItemAction {
      float: right;
      // margin-right: 12px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #192a56;
      background-color: transparent;
      height: 40px;
      width: 70px;
      border: none;
      margin-top: 3px;
    }
  
    .addItemAction:hover {
      cursor: pointer;
    }
  
    .deleteItemAction {
      float: left;
      margin-left: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #192a56;
      background-color: transparent;
      height: 40px;
      width: 70px;
      border: none;
      margin-top: 3px;
    }
  
    .deleteItemAction:hover {
      cursor: pointer;
    }
  
    .dropdownItemText {
      max-width: 93%;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
    }
  }
  