$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.updateButtonsWrapper {
    // width: 340px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 20px;
}

.selectEmployee {
    display: flex;
    flex-direction: column;
    gap: 45px;
    width: 320px;

    .editDepartment {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 10px;
        text-transform: capitalize;

        div {
            display: flex;
            gap: 5px;

            p {
                color: $primary-color;
                word-break: break-word;
            }
        }

        a {
            text-decoration: underline;
            font-size: 0.9rem;
            cursor: pointer;
            color: $secondary-color;
            font-weight: 500;
        }
    }

    .employeeName {
        display: flex;
        justify-content: space-between;

        .employeeNameLabel {
            margin-right: 25px;
            flex: none;
        }

        select {
            padding: 5px;
            min-width: 145px;
            width: 100%;
            font-size: 0.9rem;
            cursor: pointer;
            border: none;
            outline: 2px solid #ebebeb;
            border-radius: 2px;
            color: #444444;
        }

        select:focus {
            outline: 2px solid $primary-color;
        }
    }

    .codeInputs {
        display: flex;
        flex-direction: column;
        gap: 17px;
    }
}

@media screen and (max-width: 600px) {
    h2 {
        font-size: 1.2rem;
    }

    .selectEmployee {
        width: 100%;

        .editDepartment {
            flex-wrap: wrap;
        }

        .employeeName {
            flex-wrap: wrap;
            row-gap: 10px;
        }
    }
}