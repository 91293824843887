$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;
.modalContent{
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.modalText{
    // font-weight: bold;
    font-size: 18px;
}
.tasksContainer{
    height: 40vh;
    overflow-y: auto;
    width: 100%;
}
.taskWrapper{
    margin: 20px 0px;
    color: red;
}
.orders {
    display: flex;
    flex-flow: column;
    line-height: 25px;
    gap: 18px;
    width: 50vw;
    //text-transform: capitalize;
    .info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 0.8rem;
        p {
            color: $secondary-color;
        }
    }
    //Loading Spinner
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        align-self: center;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 5px solid $secondary-color;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $secondary-color transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    ///
    .order {
        display: flex;
        flex-flow: column;
        gap: 6px;
        flex: 0 0 48%;
        position: relative;
        left: 24px;
        background: #f7f7f7;
        padding: 6px 10px;
        margin-right: 24px;
        border-radius: 4px;
        .itemsListIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: -28px;
            width: 25px;
            height: 25px;
            cursor: pointer;

            i {
                background-size: cover;
                display: flex;
                width: 10px;
                height: 10px;
            }
            .showItemsIcon {
                background-image: url("../../assets/icons/minus.svg");
            }
            .hideItemsIcon {
                background-image: url("../../assets/icons/plus.svg");
            }
        }
        .itemsListIcon:hover {
            background-color: #f7f7f7;
            border-radius: 50%;
        }
        .items {
            display: flex;
            flex-flow: column;
            position: relative;
            left: 24px;
            gap: 4px;
        }
    }
    .button {
        width: 150px;
        display: flex;
        align-self: center;
    }
    .serachBox {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        div {
            display: flex;
            position: absolute;
            width: 38px;
            height: 38px;
            justify-content: center;
            align-items: center;
            i {
                background-image: url("../../assets/icons/search.svg");
                background-size: cover;
                width: 15px;
                height: 15px;
            }
        }
        input {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 10px 10px 10px 40px;
        }
        input:focus {
            outline: 1px solid $primary-color;
        }
    }
}

.dropdownwrapper{
    // position: relative;
    max-width: fit-content;
}

.updateButtonsWrapper{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 20px; 
    width: 340px; 
}
.buttonsRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
}