$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    .div {
        height: 100%;
    }
    .loginSection {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            max-width: 400px;
            min-width: 300px;
            width: 80%;
            background-color: rgba($color: #ababab, $alpha: 0.1);
            padding: 30px 35px;
            border-radius: 8px;
            box-shadow: 0px 4px 4px #d2d2d2;
            gap: 10px;
            h2 {
                margin-bottom: 5px;
                color: $primary-color;
                font-size: 1.6rem;
            }
            .phone {
                font-size: 1.5rem;
                margin-top: -13px;
                font-weight: 500;
            }
            .editPhone {
                width: 100%;
                font-size: 0.9rem;
                text-decoration: underline;
                font-weight: 500;
                color: $secondary-color;
                margin-top: -8px;
                cursor: pointer;
            }
            .codeInputs {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin: 10px 0;
                flex-direction: column;
                gap: 11px;
                .codeLabel {
                    margin: 20px 0 12px 0;
                }
                .resend {
                    display: flex;
                    width: 100%;
                    font-size: 0.9rem;
                }
                .resendActiveButton {
                    cursor: pointer;
                    color: #1f1f1f;
                    text-decoration: underline;
                }
                .resendInactiveButton {
                    cursor: not-allowed;
                    color: #8b8b8b;
                }
            }
        }
        .errorMessage {
            position: fixed;
            bottom: 20px;
            background: #f6f6f6;
            color: #ffffff;
            padding: 15px 20px;
            min-width: 300px;
            border-radius: 5px;
            box-shadow: -2px 2px 4px #d1d1d1;

            p {
                font-weight: 500;
                color: $error-color;
            }
        }
        .showErrorMessage {
            left: 18px;
            transition: all 0.3s ease-in-out;
        }
        .hideErrorMessage {
            left: -300px;
            transition: all 0.2s;
        }
    }
    .imageSection {
        width: 50%;
        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            min-height: 100%;
            position: fixed;
        }
    }
}
@media screen and (max-width: 1000px) {
    .login {
        .loginSection {
            width: 100%;
            form {
                background-color: #ffffff;
                box-shadow: none;
            }
        }
        .imageSection {
            width: 100%;
            z-index: -1;
            position: fixed;
            top: 0;
        }
    }
}
