.ModalStyle {
    // align-items: center;
    // height: 100%;
    .modalHide {
      display: none;
      position: fixed;
      z-index: 1;
      // padding-top: 100px; 
      left: 0;
      top: 0;
      width: 100%;
      // height: 100%; 
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.4);
  
      margin: 0 auto;
      height: 50%;
    }
  
    .modal {
      display: block;
      position: fixed;
      z-index: 10;
      left: 0;
      top: 0;
      width: 100%;
      // padding-top: 40px;
      // padding-bottom: 40px;
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.4);
      vertical-align: middle;
      margin: 0 auto;
      height: 100%;
      z-index: 6;
    }
  
    .modalContentHidden {
      display: none;
    }
  
    .modalContent {
      position: fixed;
      z-index: 10;
      background-color: #fefefe;
      border: 1px solid #888;
      min-width: 480px;
      max-width: 1200px;
      border-radius: 8px;
      background-color: #ffffff;
      text-align: center;
      margin: auto;
      // min-height: 50%;
      max-height: calc(100vh - 80px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  
    .modalText {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #222222;
      margin-top: 40px;
      direction: rtl;
    }
  
    .close {
      position: absolute;
      color: #aaaaaa;
      float: left;
      font-size: 28px;
      font-weight: bold;
      left: 20px;
      top: 17px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  
    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  
    .cancelModal {
      font-size: 16px;
      font-weight: normal;
      color: #717171;
      font-family: icomoon;
    }
  
    .ModalTitle {
      background-color: rgb(255, 255, 255);
      position: absolute;
      width: 100%;
      height: 80px;
      border-radius: 8px 8px 0px 0px;
      text-align: center;
    }
  
    .ModalTitleScroll {
      background-color: rgb(255, 255, 255);
      position: absolute;
      width: 100%;
      height: 80px;
      border-radius: 8px 8px 0px 0px;
      text-align: center;
      border-bottom: 2px solid #eeeeee;
    }
  
    .ModalContentInner {
      margin-top: 78px;
      max-height: calc(100vh - 170px);
      overflow-y: scroll;
      padding-left: 24px;
      padding-right: 10px;
    }
  
    .upperTitleText {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24px;
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #192a56;
    }
  
    /*-----------------------scroll bar----------------
  --------------------------------------------------*/
  
    /* width */
    .ModalContentInner::-webkit-scrollbar {
      width: 13px;
      padding: 0px;
    }
  
    /* Track */
    .ModalContentInner::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 50px;
    }
  
    /* Handle */
    .ModalContentInner::-webkit-scrollbar-thumb {
      background-color: #192a56;
      border: 5px solid transparent;
      border-radius: 50px;
      background-clip: content-box;
    }
  
    /* Handle on hover */
    .ModalContentInner::-webkit-scrollbar-thumb:hover {
      background: #192a56;
      cursor: pointer;
      border: 5px solid transparent;
      border-radius: 50px;
      background-clip: content-box;
    }
  
    /*-----------------------scroll bar----------------
  --------------------------------------------------*/
    .modalFooter {
      height: 24px;
      background-color: #ffffff;
      border-top: 1px solid #ffffff;
      transition: all .3s ease;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  
    .modalFooterScroll {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      height: 24px;
      // border-top: 2px solid #eeeeee;
      background-color: #ffffff;
      transition: all .3s ease;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  