$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

// .departments {
//     display: flex;
//     flex-flow: wrap;
//     column-gap: 45px;
//     row-gap: 25px;
//     justify-content: center;
//     user-select: none;

//     .department {
//         cursor: pointer;
//         background-color: #f2f2f2;
//         padding: 15px 20px;
//         width: max-content;
//         min-width: 120px;
//         border-radius: 4px;
//         box-shadow: 2px 2px 2px #c0c0c0;
//         text-align: center;
//         text-decoration: none;
//         text-transform: capitalize;
//         input[type="checkbox"] {
//             display: none;
//         }
//     }
//     .department:hover {
//         opacity: 0.9;
//     }
//     .selectedDepartment {
//         background-color: #8c8c8c;
//         color: #ffffff;
//     }
//     .button {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         margin-top: 20px;
//         > div {
//             width: 150px;
//         }
//     }
// }
.updateButtonsWrapper{
    // width: 340px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 20px;  
}
.Departments {
    display: flex;
    flex-flow: column;
    line-height: 25px;
    gap: 18px;
    width: 50vw;

    //text-transform: capitalize;
    .info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 0.8rem;

        p {
            color: $secondary-color;
        }
    }

    //Loading Spinner
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        align-self: center;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 5px solid $secondary-color;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $secondary-color transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .content {
        overflow-y: scroll;
        display: flex;
        flex-flow: column;
        line-height: 25px;
        gap: 18px;
        height: 40vh;

        .department {
            display: flex;
            flex-flow: column;
            gap: 6px;
            position: relative;
            left: 24px;
            background: #f7f7f7;
            padding: 6px 10px;
            margin-right: 24px;
            border-radius: 4px;

            .items {
                display: flex;
                flex-flow: column;
                position: relative;
                left: 24px;
                gap: 4px;
            }
        }
    }

    .button {
        width: 150px;
        display: flex;
        align-self: center;
    }

    .serachBox {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        div {
            display: flex;
            position: absolute;
            width: 38px;
            height: 38px;
            justify-content: center;
            align-items: center;

            i {
                background-image: url("../../assets/icons/search.svg");
                background-size: cover;
                width: 15px;
                height: 15px;
            }
        }

        input {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 10px 10px 10px 40px;
        }

        input:focus {
            outline: 1px solid $primary-color;
        }
    }
}