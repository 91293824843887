$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.layout {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    top: 0;
    display: flex;
    flex-flow: column;
    .image {
        width: 100%;
        height: 50%;
        object-fit: cover;
    }
    .color {
        background: #f9f9f9;
        width: 100%;
        height: 100%;
    }
    
}
@media screen and (max-width:500px) {
    .layout {
        .image {
            height: 100%;
        }
    }
}
