.result{
    >div{
        min-width: 280px;
    }
    h2{
        font-size: 2.5rem;
        top: -60px !important;
    }
    p{
        font-size: 1.25rem;
        margin-top: 10px;
        text-align: center;
    }
    button{
        margin-top: 80px;
    }
}