$primary-color: #002c3f;
$secondary-color: #1e5971;
$error-color: #ff4335;

.codeInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 17px;
    label {
        width: fit-content;
        display: flex;
        gap: 12px;
        justify-content: center;
        position: relative;
        height: 50px;
        align-items: center;
        .clear {
            cursor: pointer;
            position: absolute;
            right: -30px;
            bottom: 0;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                background-image: url("../../../assets/icons/eraser.svg");
                width: 15px;
                height: 15px;
                background-size: cover;
            }
        }
        .clear:hover {
            opacity: 0.8;
        }
        input {
            background-color: rgba($color: #4f4f4f, $alpha: 0.1);
            border-radius: 4px;
            border: none;
            width: 45px;
            height: 45px;
            text-align: center;
            font-size: 1rem;
        }
        :focus {
            outline: 2px solid #013950;
        }

        p {
            position: absolute;
            bottom: -24px;
            font-weight: 500;
            color: $error-color;
            font-size: 0.75rem;
            width: max-content;
            max-width: 365px;
        }
    }
}
@media screen and (max-width: 600px) {
    .codeInput {
        label {
            gap: 8px;
            
            .clear {
                right: 8px;
                bottom: -20px;
            }
            input {
                width: 40px;
                height: 40px;
            }
        }
    }
}
